<template>
  <div>
    <div v-if="!isApplied">
      <h2 class="mb-2">Application Status:</h2>
      <div class="mt-2 pb-1" style="border-top:1px solid #eee; padding:0.5rem 0; display:flex; align-items: center; justify-content: space-between;">
      </div>
       <span>Please click the green button for each day you want to work.</span>
    </div>
  	<div v-if="isApplied">
      <Loader v-if="!eventDays" />
      <h2 class="mb-2">Application Status:</h2>
  		<div v-for="day in eventDays" :key="day">
        
        <div v-for="app in appInfo(day)">
          
          <!-- <h2 class="mt-2 pb-1" style="border-bottom:1px solid #eee;" v-if="app.dayStatus == 'hired' && app.status != 'assigned'">Application Status: {{app.dayStatus | uppercase }}</h2>
          <span v-if="app.dayStatus == 'hired' && app.status != 'assigned'">We need you to work on this day. Your shift and location will be assigned shortly.</span>
   -->
          

          <div v-if="app.status == 'available'" class="mt-2 pb-1" style="border-top:1px solid #eee; padding:0.5rem 0; display:flex; align-items: center; justify-content: space-between;">
            <h5>{{day | moment("dddd, MMMM Do") }}</h5>
            <!-- <button class="btn btn__success ml-5">
              Available
            </button> -->
          </div>
           <span v-if="app.status == 'available'">Thank you for marking yourself as available to work. We are reviewing applications and will confirm your spot as soon as posssible.</span>


           <div v-if="app.dayStatus == 'hired' && app.status != 'assigned'" class="mt-2 pb-1" style="border-top:1px solid #eee; padding:0.5rem 0; display:flex; align-items: center; justify-content: space-between;">
            <h5>{{day | moment("dddd, MMMM Do") }}</h5>
            <button class="btn btn__success ml-5">
              Hired
            </button>
          </div>
           <span v-if="app.dayStatus == 'hired' && app.status != 'assigned'">We need you to work on this day. Your shift and location will be assigned shortly.</span>


           <div v-if="app.status == 'assigned'" class="mt-2 pb-1" style="border-top:1px solid #eee; padding:0.5rem 0; display:flex; align-items: center; justify-content: space-between;">
            <h5>{{day | moment("dddd, MMMM Do") }}</h5>
            <button class="btn btn__success ml-5">
              Shift Assigned
            </button>
          </div>
           <span v-if="app.status == 'assigned'"><strong>We are counting on you to be there.</strong> If you need to cancel your shift, please contact you manager right away.</span>

           <div v-if="app.dayStatus == 'not requested' && app.status != 'assigned'" class="mt-2 pb-1" style="border-top:1px solid #eee; padding:0.5rem 0; display:flex; align-items: center; justify-content: space-between;">
            <h5>{{day | moment("dddd, MMMM Do") }}</h5>
            <button class="btn btn__danger ml-5">
              Not Hired
            </button>
          </div>
           <span v-if="app.dayStatus == 'not requested' && app.status != 'assigned'">Unfortunately, we didn't need you for this event.</span>
         
        </div>
  		</div>
  	</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
const fb = require('../firebaseConfig.js')

export default {
  props: ['eventDays', 'past'],
  data: () => ({
    performingRequest: false,
    activeApp: null,
  }),
  components: {
    Loader
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'userAvailableDays', 'userAvailableData']),
    activeItem() {
      return this.eventDays[0]
    },
    appliedDays() {
      return this.eventDays.filter(day => {
        return this.userAvailableDays.includes(day)
      })
    },
    isApplied() {
      return this.appliedDays.length >= 1
    }
  },
  methods: {
    available(day) {
      return this.userAvailableDays.includes(day)
    },
    appInfo(day) {
      return this.userAvailableData.filter(data => {
        return (data.userId == this.currentUser.uid) && (data.day == day)
      })
    },
    isAvailable(day) {
      this.performingRequest = true
      fb.userDaysCollection.add({
        userId: this.currentUser.uid,
        firstName: this.userProfile.firstName,
        lastName: this.userProfile.lastName,
        day: day,
        start: day,
        status: "available",
        fullName: this.userProfile.firstName + ' ' + this.userProfile.lastName
      }).then(
        doc => {
          fb.userDaysCollection.doc(doc.id).update({
          created: fb.firestore.FieldValue.serverTimestamp(),
          id: doc.id, 
        })
      })
      this.performingRequest = false
    },
    notAvailable(day) {
      this.performingRequest = true
      fb.userDaysCollection.where("userId", "==", this.currentUser.uid).where("day", "==", day).get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          console.log(doc.id, " => ", doc.data())
          fb.userDaysCollection.doc(doc.id).delete()
          console.log("deleted")
        })
      })
      this.snackbar = false
      this.performingRequest = false
    },
  },
  beforeDestroy () {
    this.performingRequest = null
    delete this.performingRequest
  },
}
</script>