<template>
  <div class="shiftCard" style="background: white; padding: 1.6rem; width:100%;" v-if="shift">
    <h2 class="mb-2" v-if="shift.position">{{shift.position.title}}</h2>
    <div class="mb-3" v-if="shift.position">${{shift.position.rate}}/hour</div>

    <!-- IF IS HIRED FOR SHIFT -->
    <button 
      class="btn btn__success mr-3" 
      v-if="isConfirmed">
      Confirmed <i class="fas fa-lock-alt ml-2"></i>
    </button>
    <div 
      class=" mt-2"
      v-if="isConfirmed"
      >
      See you there!
    </div>

    <button 
      class="btn btn__large btn__success mr-3" 
      v-if="isAssigned && !isPermanent" 
      @click="confirm(myAssignment[0])">
      Click to Confirm Your Spot
    </button>
    <button 
      class="btn btn__large btn__success mr-3" 
      v-if="isAssigned && isPermanent" 
      @click="confirm(myAssignment[0])">
      Click to Confirm this Job
    </button>

    <!-- IF IS AVAILABLE -->
      <button class="btn btn__large btn__primary" 
        v-if="isAvailable && !isAssigned && !isConfirmedDifferentShift && !isConfirmed && !isPermanent"
        @click="isAvailableToWork(shift.day)"
        >
        Request Shift
      </button>

      <button class="btn btn__large btn__primary" 
        v-if="isAvailable && !isAssigned && !isConfirmedDifferentShift && !isConfirmed && isPermanent"
        @click="applyToWork()"
        >
        Apply
      </button>

      <!-- IF IS PENDING -->
      <button class="btn btn__warning" 
        v-if="isPending">Application Pending
      </button>

      <!-- HIRED FOR DAY -->
      <button class="btn btn__large btn__primary" v-if="isHired && !isPermanent">Hired For This Day</button>
      <button class="btn btn__large btn__primary" v-if="isHired && isPermanent">Hired For This Job</button>

      <!-- HIRED FOR DAY -->
      <div class="mt-2 " v-if="isHired">Your shift confirmation will be coming shortly</div>

       <!-- IF IS CONFIRMED FOR ANOTHER SHIFT -->
       <div v-if="isConfirmedDifferentShift && !isPermanent">
           <button class="btn btn__large btn__outlined">You've Been Assigned a Different Shift</button>
        </div>
        <div v-if="isConfirmedDifferentShift && isPermanent">
           <button class="btn btn__large btn__outlined">You've Been Assigned a Different Job</button>
        </div>

      <!-- NOT HIRED -->
      <button class="btn btn__large btn__danger" v-if="isNotHired && !isPermanent">Not Hired For This Shift</button>
      <button class="btn btn__large btn__danger" v-if="isNotHired && isPermanent">Not Hired For This Job</button>


      <div v-if="(isHired || isPending || isAssigned) && (!userAvailableDataDay[0].confirmed || userAvailableDataDay[0].confirmed == false)" class="mt-3">

        <button class="btn btn__small btn__flat btn__outlined mr-3" 
          @click="selectItem(shift.id)" 
          v-show="activeItem != shift.id" 
          v-tooltip="'Leave a note'">
          Leave a Note <i class="ml-2 far fa-sticky-note" style="color:blue"></i>
        </button>

        <transition name="modal">
          <div v-if="activeItem == shift.id">
            <AvailabilityNote :day="shift.day" :userAvailableData="userAvailableDataDay[0]" @close="closeItem(shift.day)" />
          </div>
        </transition>

        <button class="btn btn__small btn__danger__outlined" 
          v-show="activeItem != shift.id" 
          @click="notAvailable(shift.day)" 
          v-tooltip="'Cancel'">
          I Can No Longer Work <i v-if="!performingRequest" class="fas fa-times ml-2"></i>
          <transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button>

      </div>

   

    <hr class="mt-3" v-if="shift && shift.details && isConfirmed">
    <div class="strong mb-1" v-if="shift && shift.details && isConfirmed">Shift Details</div>
    <div class=" mb-1" v-if="shift && shift.details && isConfirmed">{{shift.details}}</div>

    <hr class="mt-3">
<!--     <div v-if="isAssigned || isConfirmed">
      <h4 v-if="shift && shift.event">{{shift.event}}</h4>
    </div> -->

    <div v-if="isPermanent">
      <h4 class="mb-2">Shift Schedule</h4>
      <div class="caption mb-1" v-if="shift && shift.sundayStart && shift.sundayEnd">
        Sunday: {{formatAMPM(shift.sundayStart)}} - {{formatAMPM(shift.sundayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.mondayStart && shift.mondayEnd">
        Monday: {{formatAMPM(shift.mondayStart)}} - {{formatAMPM(shift.mondayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.tuesdayStart && shift.tuesdayEnd">
        Tuesday: {{formatAMPM(shift.tuesdayStart)}} - {{formatAMPM(shift.tuesdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.wednesdayStart && shift.wednesdayEnd">
        Wednesday: {{formatAMPM(shift.wednesdayStart)}} - {{formatAMPM(shift.wednesdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.thursdayStart && shift.thursdayEnd">
        Thursday: {{formatAMPM(shift.thursdayStart)}} - {{formatAMPM(shift.thursdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.fridayStart && shift.fridayEnd">
        Friday: {{formatAMPM(shift.fridayStart)}} - {{formatAMPM(shift.fridayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.saturdayStart && shift.saturdayEnd">
        Saturday: {{formatAMPM(shift.saturdayStart)}} - {{formatAMPM(shift.saturdayEnd) | moment("h:mm:ss a") }}
      </div>
    </div>

    <div class=" strong mb-2 mt-3" v-if="((shift && shift.day) && !isPermanent)">{{shift.day | moment("dddd, MMMM Do") }}</div>
    <div class=" strong mb-3" v-if="shift && shift.startTime && !isPermanent">{{formatAMPM(shift.startTime) | moment("h:mm:ss a") }} - {{formatAMPM(shift.endTime) | moment("h:mm:ss a") }}</div>

    <div v-if="!isAssigned && !isConfirmed">
      <div class="strong caption mb-2" v-if="shift && shift.location">
        <i class="fa-regular fa-location-dot mr-2"></i> 
        {{shift && shift.location}}
      </div>
    </div>

    <span 
      v-if="isAssigned || isConfirmed">

      <hr />

      <h4 v-if="shift && shift.venue">{{shift && shift.venue}}</h4>
      <div v-if="shift && shift.address">

        <!-- <i class="fa-regular fa-location-dot mr-2"></i> {{shift.venue.address.city}}, {{shift.venue.address.state}} -->


        <div class="mb-3">{{shift.address.street_number}} {{shift.address.street}} <span v-if="shift.address.unit">#{{shift.address.unit}}</span><br />{{shift.address.city}}, {{shift.address.state}} {{shift.address.zip}}</div>

      </div>


      <div v-if="shift && shift.venue" class="mt-3">



      <hr class="mt-4">

      <h4>Staffing Manager<span v-if="event.mgrs && event.mgrs.length >= 2">s</span>:</h4>
      <div v-for="(item, index) in event.mgrs" :key="index" class="mb-3">
        <h6 class="mb-1">{{item.name}}</h6>
        <div class="mb-1"  v-if="item.phone">
          <a :href="`tel:${item.phone}`">{{item.phone}}</a></div>
        <div class="mb-1">
          <a :href="`mailto:${item.email}`">{{item.email}}</a>
        </div>
      </div>
      <hr class="mt-1" v-if="isConfirmed && (shift.eventInfo.files && shift.eventInfo.files.length >= 1) || (shift.files && shift.files.length >= 1)">

    <h5 class="pb-1" v-if="isConfirmed && (shift.eventInfo.files && shift.eventInfo.files.length >= 1) || (shift.files && shift.files.length >= 1)">Attachments</h5>
    <div v-if="(shift.eventInfo.files && shift.eventInfo.files.length >= 1) || (shift.files && shift.files.length >= 1)">
      <div v-for="(item, index) in shift.eventInfo.files" :key="index + `a`">
        <div class="flex justify-space-between align-center pt-2 pb-2" style="border-bottom:1px solid rgba(0,0,0,0.1);">
          <span>
          <div class="strong">{{item.title}}</div>
          <span class="caption">{{item.description}}</span>
          </span>
          <a :href="item.url" target="_blank">
            <button class="btn btn__outlined btn__small">
              View Attachment<i class="fas fa-external-link ml-2"></i>
            </button>
          </a>
        </div>
      </div>
      <div v-for="(item, index) in shift.files" :key="index + `a`">
        <div class="flex justify-space-between align-center pt-2 pb-2" style="border-bottom:1px solid rgba(0,0,0,0.1);">
          <span>
          <div class="strong">{{item.title}}</div>
          <span class="caption">{{item.description}}</span>
          </span>
          <a :href="item.url" target="_blank">
            <button class="btn btn__outlined btn__small">
              View Attachment<i class="fas fa-external-link ml-2"></i>
            </button>
          </a>
        </div>
      </div>
    </div>
      <!-- <div v-if="event.venue && event.files && event.files.length >= 1">
        <div v-for="(item, index) in event.files" :key="index + `b`">
          <div class="flex justify-space-between align-center pt-3 mb-3" style="border-top:1px solid rgba(0,0,0,0.1);">
            <h5>{{item.title}}</h5>
            <a :href="item.url" target="_blank">
              <button class="btn btn__outlined btn__small">
                View Attachment<i class="fas fa-external-link ml-2"></i>
              </button>
            </a>
          </div>
        </div>
      </div> -->

      <!-- <hr class="mt-1" v-if="(event.covid || event.venue.covid)">

      <div v-if="(event.covid || event.venue.covid)">
        <h4>COVID Information</h4>
        <div class="" v-if="event.covid" v-html="event.covid"></div>
        <div class=""v-if="!event.covid && (event.venue && event.venue.covid)" v-html="event.venue.covid"></div>
      </div> -->


      <hr class="mt-1" v-if="(event.attire || (event.venue && event.venue.attire))">

      <div v-if="(event.attire || (event.venue && event.venue.attire))">
        <h4>Attire</h4>
        <div class="" v-if="event.venue.attire" v-html="event.venue.attire"></div>
        <!-- <div class="" v-if="!event.attire && (event.venue && event.venue.attire)" v-html="event.venue.attire"></div> -->
      </div>

      <hr class="mt-1" v-if="(event.checkin || (event.venue && event.venue.checkin))">

      <div v-if="(event.checkin || (event.venue && event.venue.checkin))">
        <h4>Check-In Instructions</h4>
        <div v-if="event.checkin" v-html="event.checkin"></div>
        <div v-if="!event.checkin && (event.venue && event.venue.checkin)" v-html="event.venue.checkin"></div>
      </div>

      <hr class="mt-1" v-if="(event.parking || (event.venue && event.venue.parking))">

      <div class="dashboard__container--body--col" v-if="(event.parking || (event.venue && event.venue.parking))">
        <h4>Parking Details</h4>
        <div class="" v-if="event.parking" v-html="event.parking"></div>
        <div class="" v-if="!event.parking && (event.venue && event.venue.parking)" v-html="event.venue.parking"></div>
      </div>

      <hr class="mt-1" v-if="(event.creds || (event.venue && event.venue.creds))">
      
      <div class="dashboard__container--body--col" v-if="(event.creds || (event.venue && event.venue.creds))">
        <h4>Credentials (wristbands)</h4>
        <div class="" v-if="event.creds" v-html="event.creds"></div>
        <div class="" v-if="!event.creds && (event.venue && event.venue.creds)" v-html="event.venue.creds"></div>
      </div>

      <hr class="mt-1" v-if="(event.camping || (event.venue && event.venue.camping))">

      <div class="dashboard__container--body--col" v-if="(event.camping || (event.venue && event.venue.camping))">
        <h4>Camping Details</h4>
        <div class="" v-if="event.camping" v-html="event.camping"></div>
        <div class="" v-if="!event.camping && (event.venue && event.venue.camping)" v-html="event.venue.camping"></div>
      </div>

      <hr class="mt-1" v-if="(event.notes || (event.venue && event.venue.notes))">

      <div class="dashboard__container--body--col" v-if="(event.notes || (event.venue && event.venue.notes))">
        <h4>Additional Notes</h4>
        <div class="" v-if="event.notes" v-html="event.notes"></div>
        <div class="" v-if="!event.notes && (event.venue && event.venue.notes)" v-html="event.venue.notes"></div>
      </div>

      </div>
    </span>
    





    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AvailabilityNote from '@/components/AvailabilityNote.vue'
import Loader from '@/components/Loader.vue'
import AvailabilityNotice from '@/components/AvailabilityNotice.vue'
const fb = require('../../firebaseConfig.js')

export default {
  props: ['shift'],
  data: () => ({
    performingRequest: false,
    activeItem: null,
    activeApp: null,
    requestedJob:{},
    activeShiftAssignments: []
  }),
  mounted() {
    this.getActiveShiftAssignments()
  },

  // async mounted() {
  //   await this.$store.dispatch('getActiveShiftAssignments', this.shift.id)
  //   await this.$store.dispatch("getEventFromId", this.shift.eventId)
  // },
  created() {
    this.$store.dispatch("getEventFromShiftId", this.shift.eventId)
  },
  //   if (this.shift && this.shift.id) {
      
  //   }
  // },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'userAvailableDays', 'userAvailableData', 'myShifts', 'eventInfo']),
    // eventCheck() {
    //   if (this.eventInfo && !this.eventInfo.id) {
    //     this.$store.dispatch("getEventFromId", this.shift.eventId)
    //   }
    // },
    isPermanent() {
      return this.shift && this.shift.type && this.shift.type == 'Permanent'
    },
    userAvailableDataDay() {
      return this.userAvailableData.filter(data => {
        return (data.day == this.shift.day)
      })
    },
    // isAvailable() {
    //   if (
    //     //user is available to work this day
    //     (!this.userAvailableDataDay || !this.userAvailableDataDay[0]) || 
    //     //they have not already applied to work this shift
    //     ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id != this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift != this.shift.id)) ||
    //     //they haven't been not requested
    //     (this.userAvailableDataDay[0].dayStatus || this.userAvailableDataDay[0].dayStatus != 'not requested')
    //     //they haven't been confirmed to work
    //     && 
    //     (!this.myAssignment) 
    //     // &&
    //     // they haven't been assigned another shift
    //     // (this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status != "assigned")
    //     ) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    isPending() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they haven't been requested
        (this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'available') && 
        //they haven't been hired
        !this.userAvailableDataDay[0].dayStatus
        //they haven't been confirmed to work
        && 
        (!this.myAssignment || !this.myAssignment[0])
      ) { 
        return true
      } else {
        return false
      }
    },
    isHired() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they haven't been requested
        (this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'available') && 
        //they have been hired
        (this.userAvailableDataDay && this.userAvailableDataDay[0] && this.userAvailableDataDay[0].dayStatus && this.userAvailableDataDay[0].dayStatus == 'hired')

      ) {
        return true
      } else {
        return false
      }
    },
    

    isNotHired() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they have been not hired
        (this.userAvailableDataDay && this.userAvailableDataDay[0] && this.userAvailableDataDay[0].dayStatus && this.userAvailableDataDay[0].dayStatus == 'not requested')
      ) {
        return true
      } else {
        return false
      }
    },
    isAssigned() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they have been requested
        (this.userAvailableDataDay[0] && this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'assigned') && 
        //they have been been assigned this shift
        (this.myAssignment && this.myAssignment[0] && (this.myAssignment[0].shiftId == this.shift.id)) &&
        //they have not accepeted yet
        (!this.myAssignment[0].confirmed || 
          this.myAssignment[0].confirmed == false)
      ) {
        return true
      } else {
        return false
      }
    },
    isConfirmed() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they have been requested
        (this.userAvailableDataDay[0] && this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'assigned') && 
        //they have been been assigned this shift
        (this.myAssignment && this.myAssignment[0] && (this.myAssignment[0].shiftId == this.shift.id)) &&
        //they have accepeted
        (this.myAssignment[0].confirmed)
      ) {
        return true
      } else {
        return false
      }
    },

    isConfirmedDifferentShift() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift != this.shift.id) && 
        //they have been requested
        (this.userAvailableDataDay[0] && this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'assigned') &&
        (!this.myAssignment || !this.myAssignment[0])
      ) {
        return true
      } else {
        return false
      }
    },
    myAssignment() {
      return this.activeShiftAssignments.filter(assignment => {
        return (assignment.userId == this.userProfile.id)
      })
    },
    event() {
      return this.eventInfo
    },
    // userAppCheck() {
    //   return this.userAvailableDays.includes(this.event.days[0])
    // }
  },
  components: {
    AvailabilityNote,
    AvailabilityNotice,
    Loader,
  },
  methods: {
    async getActiveShiftAssignments() {
      // console.log(this.shift.id)
      if (this.shift && this.shift.id) {
        await fb.assignmentsCollection.where("shiftId", "==", this.shift.id).onSnapshot(querySnapshot => {
          let assignmentsArray = []
          querySnapshot.forEach(doc => {
            // console.log(doc.data())
            // store.dispatch("getEventFromId", doc.data().eventId)
            let shift = doc.data()
            assignmentsArray.push(shift)
          })
          return this.activeShiftAssignments = assignmentsArray
        })
      }
    },
    confirm(item) {
      console.log(item)
      this.$store.dispatch('confirmFromId', item.id)
    },

    formatAMPM(date) {
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }

      return date;
    },

    







    // checkDOB(dob) {
    //   let userProfile = this.userProfile
    //   console.log(dob)
    //   var min_age = 18

    //   const d = new Date(dob);
    //   console.log(d)
    //   let year = d.getUTCFullYear();
    //   console.log(year)
    //   var month = d.getUTCMonth()
    //   console.log(month)
    //   var day = d.getUTCDate()
    //   console.log(day)

    //   console.log(year + min_age)

    //   var theirDate = new Date((year + 18), month, day)
    //   console.log(theirDate)
    //   var today = new Date
    //   console.log(today)
    //   if ( (today.getTime() - theirDate.getTime()) < 0) {
    //     alert("You must be 18 years old to work for MVP");
    //     let dob = this.userProfile.dob
    //     this.userProfile.is18 = false
    //     this.$store.dispatch('updateUser', userProfile)
    //    }
    //    else {
    //     let dob = this.userProfile.dob
    //     this.userProfile.is18 = true
    //     this.$store.dispatch('updateUser', userProfile)
    //    }
    // },
    // applyBox() {
    //   this.applyWindow = !this.applyWindow
    // },
    // newJob(day, app) {
    //   console.log(app)
    //   this.userAvailableData.filter((item) => {
    //     if (item.day == day) {
    //       fb.userDaysCollection.doc(item.id).update({
    //         requestedJob: app.requestedJob
    //       })
    //     }
    //   })
    // },
    preferEvent(day) {
      this.performingRequest = true
      console.log(day)
      this.userAvailableData.filter((item) => {
        console.log(item)
        if (item.day == day) {
          fb.userDaysCollection.doc(item.id).update({
            preferredEvent: this.event.id,
            preferredEventName: this.event.title,
            requestedJob: this.shift.position,
            shift: this. shift
          })
        }
      })
      setTimeout(() => {
        this.performingRequest = false
      }, 1000)
    },
    unpreferEvent(day) {
      this.performingRequest = true
      this.userAvailableData.filter((item) => {
        if (item.day == day) {
          fb.userDaysCollection.doc(item.id).update({
            preferredEvent: false,
            preferredEventName: null,
            requestedJob: null,
            shift: null
          })
        }
      })
      setTimeout(() => {
        this.performingRequest = false
      }, 1000)
    },
    dayNote(day) {
      return this.userAvailableData.filter(data => {
        return (data.day == day)
      })
    },
    onClickChild () {
      this.activeItem = null
    },
    selectItem(iten) {
      this.activeItem = iten
    },
    closeItem(item) {
      this.activeItem = null
    },


    available(day) {
      console.log(day)
      return this.userAvailableDays.includes(day) || console.log('false')
    },


    // appStatus(day) {
    //   let status = this.userAvailableData.filter(data => {
    //     return (data.userId == this.currentUser.uid) && (data.day == day)
    //   })
    //   if (status && status[0] && status[0].dayStatus) {
    //     return status[0].dayStatus
    //     console.log(status[0].dayStatus)
    //   }
    // },
    // appInfo(day) {
    //   return this.userAvailableData.filter(data => {
    //     return (data.userId == this.currentUser.uid) && (data.day == day) && ((data.requestedShift.id || data.shift) == this.shift.id)
    //   })
    //   return this.arr = day
    // },  
    isAvailable(day) {

      if (this.userAvailableData[0] && this.userAvailableData[0].requestedShift) {

        this.performingRequest = true
        let user = this.userProfile
        let event = {
          status: this.shift.eventStatus,
          title: this.shift.eventTitle,
          slug: this.shift.eventSlug,
        }
        let shift = this.shift
        console.log(user)
        console.log(day)
        console.log(event)
        this.$store.dispatch('addAvailablityUpdate', {
          user: user,
          day: day,
          event: event,
          shift: shift,
          id: this.userAvailableData[0].id
        })
        setTimeout(() => {
          this.performingRequest = false
        }, 3000)

      } else {

        this.performingRequest = true
        let user = this.userProfile
        let event = {
          status: this.shift.eventStatus,
          title: this.shift.eventTitle,
          slug: this.shift.eventSlug,
        }
        let shift = this.shift
        console.log(user)
        console.log(day)
        console.log(event)
        this.$store.dispatch('addAvailablity', {
          user: user,
          day: day,
          event: event,
          shift: shift
        })
        setTimeout(() => {
          this.performingRequest = false
        }, 3000)
      }
      // fb.userDaysCollection.add({
      //   userId: this.currentUser.uid,
      //   firstName: this.userProfile.firstName,
      //   lastName: this.userProfile.lastName,
      //   phone: user.phone || null,
      //   email: user.email,
      //   day: day,
      //   start: day,
      //   status: "available",
      //   fullName: this.userProfile.firstName + ' ' + this.userProfile.lastName,
      //   created: fb.firestore.FieldValue.serverTimestamp(),
      // }).then(
      //   doc => {
      //     fb.userDaysCollection.doc(doc.id).update({
      //     id: doc.id,
      //     preferredEvent: this.event.id,
      //     eventTitle: this.event.title,
      //     eventSlug: this.event.slug
      //   })
      // })
    },
    notAvailable(day) {
      this.performingRequest = true
      let drop =  {
        userId: this.currentUser.uid,
        day: day
      }
      this.$store.dispatch('dropUser', drop)
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    },
    updateProfile(){
      let userProfile = this.userProfile
      this.$store.dispatch('updateUser', userProfile)
    },




  },
  // beforeDestroy () {
  //   this.$store.dispatch('clearActiveShiftAssignments')
  // }
  beforeDestroy () {
    this.$store.dispatch('clearShiftDetails')
    this.$store.dispatch('clearEventState')
  }
}
</script>